.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--arrow-left {
    position: absolute;
    z-index: 1;
}

.react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    z-index: 1;
    right: 0;
}

.react-horizontal-scrolling-menu--inner-wrapper {
    position: relative;
}

.react-horizontal-scrolling-menu--scroll-container {
    padding-left: 5px;
    padding-right: 5px;
}
